<template>
	<div class="main-content">
		<ktv-breadcrumb :title="'Detail Dashboard'" :page="$t('Detail', 'Detail')"
			:folder="$t('User Dashboard', 'User Dashboard')"
		/>
		<div>
			<ktv-back @click="backToList()" />
		</div>
		<div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
			<!-- <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/v64KOxKVLVg" -->
			<!-- <iframe class="embed-responsive-item"
                src="https://koltiva.metabaseapp.com/embed/dashboard/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjEyfSwicGFyYW1zIjp7fX0.gRoo5txwcOmW05R1wAJemOLM1C7j7sam4ZvGIqRRCUs#bordered=false"
                allowfullscreen></iframe> -->
			<iframe class="embed-responsive-item" :src="iframeUrl" allowfullscreen></iframe>
		</div>
	</div>
</template>
<script>

	// eslint-disable-next-line no-unused-vars
	import { mapActions, mapGetters } from "vuex"
	// import { KtvButton, KtvAccor, KtvHeadForm } from "@/components"
	import jwt from "jsonwebtoken"
	export default {
		name: 'MyIframe',
		metaInfo: {
			title: "User Dashboard Form",
		},
		components: {
			// KtvButton,
			// KtvAccor,
			// KtvHeadForm,
			// ListProduct,
			// ListTrans
		},
		data() {
			return {
				iframeUrl: ''
			}
		},
		// computed: {
		//     ...mapGetters({
		//         options: "SME/getOptions",
		//         opsiDisplay: "SME/getOpsiDisplay",
		//         error: "SME/getError",
		//     }),
		// },
		watch: {
			error(val) {
				if (val != null) {
					this.makeToast("Error", "danger", val.message)
				}
			},
		},
		mounted() {
			this.onLoad()
		},
		methods: {
			backToList() {
				// this.clearData()
				this.$router.back()
			},
			onLoad() {
				const BoardID = Number(this.$route.query.BoardID)
				const METABASE_SITE_URL = "https://koltiva.metabaseapp.com"
				const METABASE_SECRET_KEY = "d60016f0c76ff89a52bfae965746af3bffa679154c4fc3be078ce339738a9c07"

				const payload = {
					resource: { 'dashboard': BoardID },
					params: {},
					exp: Math.round(Date.now() / 1000) + (10 * 60)
				}
				const token = jwt.sign(payload, METABASE_SECRET_KEY);
				this.iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=false"
				// console.log('iframeUrl', this.iframeUrl)
			}

		}

	}
</script>